import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Button from 'components/globals/button'
import Slider from 'components/globals/slider'
import Link from 'components/globals/link'

export default () => {
	const posts = useStaticQuery(graphql`
		query postsQuery {
			siteData {
                posts {
                    nodes {
                        title
                        categories {
                            nodes {
                                slug
                                name
                            }
                        }
                        featuredImage {
                            mediaItemUrl
                        }
                        link
                        postFields {
                            author {
                                name
                                role
                            }
                        }
                    }
                }
			}
		}
    `).siteData.posts.nodes

    const _posts = posts.concat(posts)

    return (
        <>
            <div className="scroll-container" id={'stories'}>
                <ul className="posts">
                    <Slider
                        className={'carousel'}
                        options={{
                            prevNextButtons: false,
                            pageDots: true,
                            autoPlay: 3000,
                            wrapAround: true,
                            selectedAttraction: 0.02,
                            friction: 0.4
                        }}
                    >
                    {_posts.map((post, index) => {
                        const {
                            postFields,
                            featuredImage,
                            title,
                            categories,
                            link
                        } = post

                        const category = categories.nodes[0]

                        return (
                            <li className={`post cat-${ category.slug }`} key={index}>
                                <Link to={link}>
                                    <div 
                                        className="image column" 
                                        style={{
                                            backgroundImage: featuredImage ? `url('${featuredImage.mediaItemUrl}')` : null
                                        }} 
                                    />
                                    <div className="text column">
                                        <div className="content">
                                            <div className="meta-row">
                                                <p className="small bold">
                                                    { postFields.author.name }
                                                </p>
                                                <p className="small bold">
                                                    { category.name }
                                                </p>
                                            </div>
                                            <h2>
                                                { title }
                                            </h2>
                                            <Button
                                                label={'Read article'}
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        )
                    })}
                    </Slider>
                </ul>
            </div>
        </>
    )
}
